export const local_theme = "smart_workplace_theme_v1";
export const feedback_email_id =
  "test - Workspace Support <77993f76.ericsson.onmicrosoft.com@emea.teams.ms>";
export const fuzzy_search_pageSize = 10;

export function getBaseUrl() {
  let app_version = process.env.REACT_APP__APP_VERSION;
  let api_base_url = process.env.REACT_APP__API_BASE_URL;
  let api_uname = process.env.REACT_APP__API_UNAME;
  let api_passwd = process.env.REACT_APP__API_PASSWD;
  let instrumentationKey = process.env.REACT_APP__INSTRUMENTATION_KEY;
  let mwp_url = process.env.REACT_APP__MWP_END_USER_PORTAL;
  return {
    app_version: app_version,
    api_base_url: api_base_url,
    api_uname: api_uname,
    api_passwd: api_passwd,
    instrumentationKey: instrumentationKey,
    mwp_url: mwp_url,
  };
}

export function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
}

export function convertToLocalDateTime(_date) {
  let utc = new Date(_date);
  let offset = utc.getTimezoneOffset();
  let local = new Date(utc.getTime() - offset * 60000).toLocaleString();
  return local;
}

export function calculateRemainingTime(_date) {
  var tomorrow = new Date(_date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var date = new Date();
  var now = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  let remainingTime = tomorrow.getTime() - now.getTime();
  let seconds = Math.floor(remainingTime / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  return `${hours}h ${minutes}m`;
}

export const maxFaultDescriptionLength = 1000;

export const maxSizeInBytes = 2 * 1024 * 1024;
export const maxSizeMB = 2;

export const renderExtStatus = (status) => {
  return status.startsWith("SYS_")
    ? !status.includes("ERROR")
      ? status.replace("SYS_", "")
      : null
    : status;
};

export const renderVendorName = (vendorName) => {
  return vendorName === "LOCAL_IT" || vendorName === "EMAIL_IT"
    ? "Local IT"
    : "Facilities";
};

export const FEATURE_FLAGS = {
  displayExtSatusForFaults:
    process.env.REACT_APP__DISPLAY_EXT_STATUS_FOR_FAULTS === "true",
  enableDevicePairing: process.env.REACT_APP__ENABLE_DEVICE_PAIRING === "true",
  enableNewPlandingPage:
    process.env.REACT_APP__ENABLE_NEW_LANDING_PAGE === "true",
};

export const DEVICE_EMAIL_PAIR_ATTRIBUTE = "fr_device_email_pair_attr";
export const DEVICE_EMAIL_PAIR_ONE_TIME_CODE_RETRY_COUNT = 3;

export function openExternalUrl(url) {
  const isOpened = window.open(url, "_blank", "noopener,noreferrer");
  if (isOpened !== null) {
    document.location.href = url;
  }
}

export const FAULT_REPORT_ROUTE = "fault-report";

export const parseUrlQuery = (url) => {
  let parsedData = {
    _theme: "light",
    _id: "",
    _mail: "",
    _source: "",
    _locationCode: "",
    _problemReported: "",
    _isFloorSearchSelected: "",
  };
  if (url) {
    if (typeof url.searchParams.get("theme") === "string") {
      parsedData._theme =
        url.searchParams.get("theme").trim() || parsedData._theme;
    }
    if (typeof url.searchParams.get("id") === "string") {
      parsedData._id = url.searchParams.get("id").trim() || parsedData._id;
    }
    if (typeof url.searchParams.get("mail") === "string") {
      parsedData._mail =
        url.searchParams.get("mail").trim() || parsedData._mail;
    }
    if (typeof url.searchParams.get("source") === "string") {
      parsedData._source =
        url.searchParams.get("source").trim() || parsedData._source;
    }
    if (typeof url.searchParams.get("locationCode") === "string") {
      parsedData._locationCode =
        url.searchParams.get("locationCode").toUpperCase().trim() ||
        parsedData._locationCode;
    }
    if (typeof url.searchParams.get("problemReported") === "string") {
      parsedData._problemReported =
        url.searchParams.get("problemReported").trim() ||
        parsedData._problemReported;
    }
    if (typeof url.searchParams.get("isFloorSearchSelected") === "string") {
      parsedData._isFloorSearchSelected =
        url.searchParams.get("isFloorSearchSelected").trim() ||
        parsedData._isFloorSearchSelected;
    }
  }
  return parsedData;
};

