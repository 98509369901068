import { appInsights } from "./AppInsights";
export function aiTrackEvent(pageName, locationCode, emailHash) {
  appInsights.trackPageView(pageName);
  appInsights.trackEvent({
    name: pageName,
    properties: {
      location_code: locationCode,
      widget_type: "global",
      user_id: emailHash,
    },
  });
}
